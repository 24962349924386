import { getCookie, setCookie } from './libs/cookies';

// Eventi che indicano quando si passa da desktop a mobile o viceversa
const mobileEvent = new Event('mobile');
const desktopEvent = new Event('desktop');

// Indica l'attuale dimensione della finestra
export let currentSize;

// Indica se il browser è safari
export const isSafari = !!navigator.userAgent.match(/Version\/[\d\.]+.*Safari/);

// Indica se il browser è edge
export const isEdge = window.navigator.userAgent.indexOf('Edge') > -1;

export const foreign = document
  .querySelector('body')
  .classList.contains('foreign');

// Aggiorna la variabile currentSize e lancia, eventualmente, gli eventi mobile o desktop
export function updateCurrentSize() {
  const actualSize = currentSize;
  if (window.innerWidth >= 1200) {
    currentSize = 'huge';
    if (actualSize === 'small' || actualSize === 'extra-small') {
      window.dispatchEvent(desktopEvent);
    }
  } else if (window.innerWidth >= 992) {
    currentSize = 'large';
    if (actualSize === 'small' || actualSize === 'extra-small') {
      window.dispatchEvent(desktopEvent);
    }
  } else if (window.innerWidth >= 768) {
    currentSize = 'medium';
    if (actualSize === 'small' || actualSize === 'extra-small') {
      window.dispatchEvent(desktopEvent);
    }
  } else if (window.innerWidth >= 576) {
    currentSize = 'small';
    if (
      actualSize === 'huge' ||
      actualSize === 'large' ||
      actualSize === 'medium'
    ) {
      window.dispatchEvent(mobileEvent);
    }
  } else {
    currentSize = 'extra-small';
    if (
      actualSize === 'huge' ||
      actualSize === 'large' ||
      actualSize === 'medium'
    ) {
      window.dispatchEvent(mobileEvent);
    }
  }
}

// fix strano
window.onpageshow = function (e) {
  if (e.persisted) {
    window.location.reload();
  }
};

function showPreloader(callback) {
  const preloaderContainer = document.querySelector('.preloader-container');
  preloaderContainer.classList.add('active');

  setTimeout(callback, 350);
}

export function basic() {
  // 100vh fix

  // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
  let vh = window.innerHeight * 0.01;
  // Then we set the value in the --vh custom property to the root of the document
  document.documentElement.style.setProperty('--vh', `${vh}px`);

  // Fix for main 100% height
  const header = document.querySelector('header');
  if (header) {
    let headerHeight = header.offsetHeight;

    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty(
      '--header-height',
      `${headerHeight}px`
    );
  }

  window.addEventListener('resize', function () {
    vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);

    if (header) {
      let headerHeight = header.offsetHeight;

      document.documentElement.style.setProperty(
        '--header-height',
        `${headerHeight}px`
      );
    }
  });

  // Cookie bar

  // if (!document.querySelector('body').classList.contains('comingSoon')) {
  //   const cookieBar = document.querySelector('.cookie-bar');

  //   if (!document.cookie.includes('cookiebar')) {
  //     cookieBar.classList.add('active');
  //   } else {
  //     const cookieDec = decodeURIComponent(document.cookie);
  //     let cookie;

  //     if (cookieDec.split(';').length > 0) {
  //       cookie = decodeURIComponent(document.cookie)
  //         .split(';')
  //         .map((c) => c.split('='))
  //         .filter((c) => c[0].trim() === 'cookiebar')[0][1];
  //     } else {
  //       cookie = decodeURIComponent(document.cookie).split('=')[1];
  //     }

  //     if (cookie === '1') {
  //       window.dataLayer = window.dataLayer || [];
  //       window.dataLayer.push({
  //         event: 'cookies-ok',
  //       });
  //     }
  //   }

  //   document
  //     .getElementById('cookie-accept')
  //     .addEventListener('click', function () {
  //       cookieBar.classList.remove('active');
  //       setCookie('cookiebar', '1', 365);
  //       // Lancia evento attivazione pixel
  //       window.dataLayer = window.dataLayer || [];
  //       window.dataLayer.push({
  //         event: 'cookies-ok',
  //       });
  //     });

  //   document
  //     .getElementById('cookie-reject')
  //     .addEventListener('click', function () {
  //       cookieBar.classList.remove('active');
  //       setCookie('cookiebar', '0', 365);
  //     });
  // }

  // menu link transitions
  const menuLinks = document.querySelectorAll(
    '.menu nav a:not(.no-transition)'
  );

  menuLinks.forEach((link) => {
    link.addEventListener('click', function (e) {
      e.preventDefault();
      const href = e.currentTarget.href;
      showPreloader(() => (location.href = href));
    });
  });
}

export function menu() {
  if (document.querySelector('.hamburger')) {
    const hamburger = document.querySelector('.hamburger');
    const menuContainer = document.querySelector('.menu-container');
    const headerLinks = document.querySelector('.header-menu__links');
    let menuOpened = false;

    const menuAnim = new TimelineMax({ delay: 0.8, paused: true });
    /*
    menuAnim
      .add('start')
      .staggerFrom(
        '.menu nav ul li',
        0.8,
        { autoAlpha: 0, y: 50, rotation: 1, ease: Power3.easeOut },
        0.1
      )
      .from(
        '.bottom-nav',
        0.5,
        {
          autoAlpha: 0
        },
        'start+=0.5'
      )
      .staggerFrom(
        '.bottom-nav > div',
        0.5,
        { autoAlpha: 0, y: 50, ease: Power3.easeOut },
        0.2,
        'start+=0.5'
      )
      .staggerFrom(
        '.info-box',
        0.5,
        { autoAlpha: 0, x: -100, ease: Power3.easeOut },
        0.2,
        'start+=0.5'
      );
*/

    menuAnim
      .add('start')
      .staggerFrom(
        '.menu > nav > ul > li',
        0.8,
        { autoAlpha: 0, y: 50, rotation: 1, ease: Power3.easeOut },
        0.1
      )
      .from(
        '.bottom-nav',
        0.5,
        {
          autoAlpha: 0,
          y: 10,
          ease: Power3.easeOut,
        },
        'start+=0.5'
      )
      .from(
        '.info',
        0.5,
        { autoAlpha: 0, y: 10, ease: Power3.easeOut },
        'start+=1'
      );

    //lottie animation
    let lottieAnim1;
    let lottieAnim2;
    let anims;
    if (currentSize !== 'small' && currentSize !== 'extra-small') {
      let path =
        currentSize === 'small' || currentSize === 'extra-small'
          ? foreign
            ? '../lottie/menu/menu-mobile-blur.json'
            : 'lottie/menu/menu-mobile-blur.json'
          : foreign
          ? '../lottie/menu/menu-blur.json'
          : 'lottie/menu/menu-blur.json';

      lottieAnim1 = lottie.loadAnimation({
        container: document.getElementById('menu-anim-1'), // the dom element that will contain the animation
        renderer: 'svg',
        loop: true,
        autoplay: false,
        path, // the path to the animation json
      });

      //lottie animation
      path =
        currentSize === 'small' || currentSize === 'extra-small'
          ? foreign
            ? '../lottie/menu/menu-mobile.json'
            : 'lottie/menu/menu-mobile.json'
          : foreign
          ? '../lottie/menu/menu.json'
          : 'lottie/menu/menu.json';
      lottieAnim2 = lottie.loadAnimation({
        container: document.getElementById('menu-anim-2'), // the dom element that will contain the animation
        renderer: 'svg',
        loop: true,
        autoplay: false,
        path, // the path to the animation json
      });

      anims = [lottieAnim1, lottieAnim2];
    }

    hamburger.addEventListener('click', function () {
      hamburger.classList.toggle('opened');
      menuContainer.classList.toggle('opened');
      headerLinks.classList.toggle('active');
      menuOpened = !menuOpened;

      if (menuOpened) {
        window.scrollTo(0, 0);
        menuAnim.play();
        if (currentSize !== 'small' && currentSize !== 'extra-small') {
          anims.forEach((anim) => anim.play());
        }
        if (
          currentSize !== 'small' &&
          currentSize !== 'extra-small' &&
          (window.screen.orientation === 'landscape-primary' ||
            window.screen.orientation === 'landscape-secondary')
        ) {
          document.documentElement.style.overflow = 'hidden';
        }
      } else {
        menuAnim.reverse();
        if (currentSize !== 'small' && currentSize !== 'extra-small') {
          anims.forEach((anim) => anim.stop());
        }
        document.documentElement.style.overflow = 'initial';
      }
    });

    document.querySelectorAll('.has-submenu').forEach(function (element) {
      element.addEventListener('click', function (e) {
        e.currentTarget.classList.toggle('opened');
        document
          .getElementById(`submenu-${e.currentTarget.dataset.submenu}`)
          .classList.toggle('active');
      });
    });
  }
}

// Gestisce l'ingresso degli elementi nello schermo
export function animations() {
  /*
  Animazione slide in
  ====================================
  */
  const slideInElements = document.querySelectorAll('.slide-in');

  const slideInObserver = new IntersectionObserver(
    (entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          slideIn(entry.target);
          if (!entry.target.classList.contains('slide-out')) {
            observer.unobserve(entry.target);
          }
        } else {
          if (
            entry.target.classList.contains('slide-out') &&
            entry.target.classList.contains('visible')
          ) {
            slideOut(entry.target);
          }
        }
        return;
      });
    },
    {
      threshold: 0.5,
    }
  );

  const slideInAltObserver = new IntersectionObserver(
    (entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          slideIn(entry.target);
          if (!entry.target.classList.contains('slide-out')) {
            observer.unobserve(entry.target);
          }
        } else {
          if (
            entry.target.classList.contains('slide-out') &&
            entry.target.classList.contains('visible')
          ) {
            slideOut(entry.target);
          }
        }
        return;
      });
    },
    {
      threshold: 0.9,
    }
  );

  slideInElements.forEach((element) => {
    slideInObserver.observe(element);
  });

  function slideIn(element) {
    element.classList.add('visible');
  }

  function slideOut(element) {
    element.classList.remove('visible');
  }
}

// Carica le immagini dinamicamente
/*
export function lazyLoad() {
  const imgElements = document.querySelectorAll('[data-src], [data-srcset]');

  const imgObserver = new IntersectionObserver(
    (entries, observer) => {
      entries.forEach(entry => {
        if (!entry.isIntersecting) {
          return;
        } else {
          lazyLoadImg(entry.target);
          observer.unobserve(entry.target);
        }
      });
    },
    {
      threshold: 0,
      rootMargin: '0px 0px 300px 0px'
    }
  );

  imgElements.forEach(element => {
    imgObserver.observe(element);
  });

  function lazyLoadImg(element) {
    if (element.dataset.src) {
      element.src = element.dataset.src;
    }
    if (element.dataset.srcset) {
      element.srcset = element.dataset.srcset;
    }
  }
}
*/

/*
import documentReady from './libs/documentReady';
*/
document.addEventListener('DOMContentLoaded', function () {
  // Aggiorna la variabile currentSize quando la finestra viene ridimensionata
  updateCurrentSize();
  window.addEventListener('resize', () => {
    updateCurrentSize();
  });

  // Aggiorna la pagina in caso di cambio di orientamento
  window.addEventListener(
    'orientationchange',
    () => {
      location.href = location.href; // TEMP: location.reload() non va
    },
    false
  );

  // Ricarica la pagina quando si passa da mobile a desktop o viceversa
  window.addEventListener('mobile', function () {
    location.href = location.href; // TEMP: location.reload() non va
  });
  window.addEventListener('desktop', function () {
    location.href = location.href; // TEMP: location.reload() non va
  });

  basic();
  //lazyLoad();
  menu();
  animations();
});
